@import 'config';

// Node modules
@import '~bootstrap/scss/bootstrap';

// Layout
@import 'layout/core';
@import 'layout/grid';
@import 'layout/navbar';
@import 'layout/page';

// UI
@import 'ui/typo';
@import 'ui/tag';
@import 'ui/icons';
@import 'ui/dropdowns';
@import 'ui/progress';
@import 'ui/modals';
@import 'ui/tabs';
@import 'ui/breadcrumbs';
@import 'ui/header';
@import 'ui/loaders';
@import 'ui/fonts';
@import 'ui/pagination';
@import 'ui/stamps';
@import 'ui/tables';
@import 'ui/badges';
@import 'ui/buttons';
@import 'ui/cards';
@import 'ui/avatars';
@import 'ui/alerts';
@import 'ui/inputs';
@import 'ui/inputs.custom';
@import 'ui/media';
@import 'ui/toolbar';
@import 'ui/login';
@import 'ui/lists';
@import 'ui/ribbons';
@import 'ui/nav';
@import 'ui/calendars';
@import 'ui/toasts';
@import 'ui/steps';
@import 'ui/charts';
@import 'ui/switch';
@import 'ui/highlight';
@import 'ui/footer';

// Vendor
@import 'vendor/pace';
@import 'vendor/selectize';

// Utils
@import 'utils/api';
@import 'utils/border';
@import 'utils/background';
@import 'utils/colors';
@import 'utils/scroll';
@import 'utils/text';
@import 'utils/sizing';
@import 'utils/opacity';
@import 'utils/shadow';

// Custom
@import 'custom';
