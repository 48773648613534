.table {
  color: inherit;
  td,
  p {
    max-width: 150px;
    overflow-wrap: break-word;
  }

  thead {
    th {
      color: #64748b;
      border-width: $table-border-width;
      background: $min-black;
      text-transform: uppercase;
      font-size: $h6-font-size;
      font-weight: 600;
    }
  }

  th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    color: $text-muted-light;
    white-space: nowrap;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-md {
  th,
  td {
    padding: 0.5rem;
  }
}

.table-vcenter {
  td,
  th {
    vertical-align: middle;
  }
}

.table-center {
  td,
  th {
    text-align: center;
  }
}

.table-clickable {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.table-fixed {
  table-layout: fixed;
}
